var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "shopping-list" },
    [
      _c("div", { staticClass: "d-flex align-center" }, [
        _c("h4", { staticClass: "text-uppercase flex-grow-1" }, [
          _vm._v(_vm._s(_vm.$t("shoppingList.title")))
        ]),
        _c(
          "div",
          [
            _c(
              "v-btn",
              { attrs: { icon: "" }, on: { click: _vm.share } },
              [_c("v-icon", [_vm._v("$share")])],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("v-text-field", {
            staticClass: "add-product-field",
            attrs: {
              color: "primary",
              placeholder: _vm.$t("shoppingList.addProduct"),
              "append-icon": "$plus",
              outlined: "",
              rounded: "",
              "hide-details": ""
            },
            on: { "click:append": _vm.addProduct },
            model: {
              value: _vm.newItem,
              callback: function($$v) {
                _vm.newItem = $$v
              },
              expression: "newItem"
            }
          })
        ],
        1
      ),
      _c(
        "v-list",
        { key: _vm.listKey, attrs: { flat: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { multiple: "" } },
            _vm._l(_vm.items, function(item, index) {
              return _c("v-list-item", {
                key: "shop-item-" + index + "-" + _vm.key,
                class: { "primary lighten-1": item.selected },
                on: {
                  click: function($event) {
                    _vm.toggleItemSelection(index)
                    _vm.key++
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.label))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "input-value": item.selected,
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.removeItem(index)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { size: "19" } }, [
                                    _vm._v("$delete")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "my-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                large: "",
                block: "",
                depressed: "",
                rounded: "",
                outlined: ""
              },
              on: { click: _vm.clearList }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shoppingList.newList")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }