<template>
  <v-container class="shopping-list">
    <div class="d-flex align-center">
      <h4 class="text-uppercase flex-grow-1">{{ $t("shoppingList.title") }}</h4>
      <div>
        <v-btn icon @click="share"><v-icon>$share</v-icon></v-btn>
      </div>
    </div>
    <div class="mt-5">
      <v-text-field
        v-model="newItem"
        color="primary"
        :placeholder="$t('shoppingList.addProduct')"
        append-icon="$plus"
        @click:append="addProduct"
        outlined
        rounded
        class="add-product-field"
        hide-details
      ></v-text-field>
    </div>
    <v-list flat :key="listKey">
      <v-list-item-group multiple>
        <v-list-item
          v-for="(item, index) in items"
          :key="'shop-item-' + index + '-' + key"
          :class="{ 'primary lighten-1': item.selected }"
          @click="
            toggleItemSelection(index);
            key++;
          "
        >
          <template v-slot:default>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox :input-value="item.selected" readonly></v-checkbox>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn icon small @click.prevent.stop="removeItem(index)"
                ><v-icon size="19">$delete</v-icon></v-btn
              >
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="my-4">
      <v-btn
        color="primary"
        large
        block
        depressed
        rounded
        outlined
        @click="clearList"
      >
        {{ $t("shoppingList.newList") }}
      </v-btn>
    </div>
  </v-container>
</template>
<style lang="scss">
.shopping-list {
  .add-product-field {
    .v-icon.v-icon.v-icon--link {
      font-size: 35px;
      color: $secondary !important;
    }
    fieldset {
      color: var(--v-grey-lighten3) !important;
    }
    .v-input__control .v-input__slot {
      min-height: 50px !important;
      display: flex !important;
      align-items: center !important;
    }
    .v-input__append-inner {
      margin-top: 13px;
    }
  }
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten1);
    .v-list-item__title {
      font-size: 16px;
    }
  }
  .v-btn .v-btn__content {
    color: var(--v-default-base);
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import socialsharing from "@/mixins/socialsharing";
export default {
  name: "ShoppingList",
  mixins: [socialsharing],
  data() {
    return {
      newItem: "",
      items: [],
      selected: [],
      key: 1,
      listKey: 1
    };
  },
  computed: {
    ...mapGetters({
      shoppingList: "custom/getShoppingList",
      getShoppingListAll: "custom/getShoppingListAll",
      user: "cart/getUser"
    })
    // list() {
    //   if (this.user && this.user.userId) {
    //     return this.shoppingList(this.user.userId);
    //   } else {
    //     return [];
    //   }
    // }
  },
  methods: {
    ...mapActions({
      addShoppingItem: "custom/addShoppingItem",
      clearShoppingList: "custom/clearShoppingList",
      toggleItemSelection: "custom/toggleItemSelection",
      removeShoppingItem: "custom/removeShoppingItem"
    }),
    addProduct() {
      if (this.newItem != "") {
        this.addShoppingItem({ label: this.newItem, selected: false });
        this.newItem = "";
      }
    },
    removeItem(index) {
      this.removeShoppingItem(index);
      this.listKey++;
    },
    async clearList() {
      let title = this.$t("shoppingList.newList") + " ";
      let res = await this.$dialog.confirm({
        text: `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-bold'>La lista della spesa attuale verrà eliminata, sei sicuro?</p>`,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: this.$t("common.cancel")
          },
          true: {
            rounded: true,
            text: this.$t("common.confirm")
          }
        }
      });
      if (res) {
        this.clearShoppingList();
        this.getList();
        this.listKey++;
      }
    },
    getList() {
      if (this.user && this.user.userId) {
        this.items = this.shoppingList(this.user.userId);
      } else {
        this.items = [];
      }
    },
    share() {
      let items = this.items.map(item => "- " + item.label).join("\n");
      var options = {
        message: items,
        subject: "Lista della spesa"
      };
      if (window.plugins && window.plugins.socialsharing) {
        window.plugins.socialsharing.shareWithOptions(
          options,
          this.onSuccess,
          this.onError
        );
      } else {
        alert("Cordova social sharing plugin not available.");
      }
    }
  },
  mounted() {
    this.getList();
  }
};
</script>
